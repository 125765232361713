<template>
  <div class="issue-status">
    <v-toolbar flat>
      <v-toolbar-title>Editar Estatus de Incidencia</v-toolbar-title>
    </v-toolbar>
    <v-container fluid>
      <v-row justify="center" class="mx-8">
        <base-error :error="error"></base-error>
      </v-row>
      <v-form v-model="isValid" ref="form" @submit="submit" class="new__issue-status__form ma-5">
        <v-row class="mb-2">
          <v-col class="pa-0">
            <v-text-field
              outlined
              :rules="rules.issueStatusName"
              label="*Nombre"
              class="mt-2"
              v-model="name"
              :error-messages="nameError"
              @keydown="nameError=null; error=null"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col class="pa-0">
            <v-text-field outlined name="description" label="Descripción" v-model="description"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col class="pa-0">
            <v-select
              disabled
              outlined
              v-model="statusId"
              item-value="id"
              item-text="name"
              :items="getIssueStatusIssueTypes"
              label="Tipo Status"
            ></v-select>
            <p class="ml-2 mt-2">*Campos obligatorios</p>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col>
            <div class="grey--text text--darken-1 mb-2">Color</div>
            <v-color-picker v-model="color" width="31.25rem" flat show-swatches hide-canvas></v-color-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              rounded
              outlined
              block
              color="primary"
              class="mt-5"
              :to="{name: 'issue-status'}"
            >Cancelar</v-btn>
          </v-col>
          <v-col>
            <v-btn
              rounded
              :loading="isLoading"
              type="submit"
              block
              color="primary"
              class="mt-5"
              :disabled="!isValid"
            >Guardar</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>
<script>
import { to } from '@/helpers'
import rules from '@/constants/input-validation'
import {
  ISSUE_STATUS_GET_ISSUE_STATUS,
  ISSUE_STATUS_UPDATE_ISSUE_STATUS,
  ISSUE_STATUS_GET_ISSUE_STATUS_TYPES
} from '@/store/actions.type'
import { errorMessage } from '@/mixins'
import { mapGetters } from 'vuex'
export default {
  name: 'IssueStatusEdit',
  props: {
    issueStatusId: {
      required: true
    }
  },
  created() {
    this.getIssueStatus()
    this.getIssueStatusTypes()
  },
  data() {
    return {
      color: '#8E00FF',
      rules,
      isValid: false,
      isLoading: false,
      description: '',
      descriptionError: '',
      name: '',
      nameError: '',
      statusId: null,
      statusError: '',
      error: null
    }
  },
  computed: {
    ...mapGetters(['getIssueStatusIssueTypes', 'getIssueStatusIssueStatus'])
  },
  components: {},
  mixins: [errorMessage],
  methods: {
    back() {
      this.$router.push('/issue-status')
    },
    async getIssueStatus() {
      this.isLoading = true
      const [err] = await to(
        this.$store.dispatch(ISSUE_STATUS_GET_ISSUE_STATUS, this.issueStatusId)
      )
      if (err) {
        // @TODO: if 404
        this.error = err

        this.isLoading = false
      } else {
        this.name = this.getIssueStatusIssueStatus.name
        this.description = this.getIssueStatusIssueStatus.description
        this.color = this.getIssueStatusIssueStatus.color
        this.statusId = this.getIssueStatusIssueStatus.statusType.id
        this.isLoading = false
      }
    },
    async getIssueStatusTypes() {
      this.isLoading = true
      const [err] = await to(
        this.$store.dispatch(ISSUE_STATUS_GET_ISSUE_STATUS_TYPES)
      )
      if (err) {
        this.error = err
        this.isLoading = false
      } else {
        this.isLoading = false
      }
    },
    async submit(evt) {
      evt.preventDefault()
      const issueStatus = {
        id: this.issueStatusId,
        name: this.name,
        description: this.description,
        color: this.color,
        statusType: {
          id: this.statusId
        }
      }
      this.isLoading = true
      this.error = null
      const [err] = await to(
        this.$store.dispatch(ISSUE_STATUS_UPDATE_ISSUE_STATUS, issueStatus)
      )
      if (err) {
        this.error = err
        this.isLoading = false
        if (err.status === 422) {
          this.nameError = this.errorMessage(err, 'name')
          this.descriptionError = this.errorMessage(err, 'description')
        }
      } else {
        this.$router.push({ name: 'issue-status' })
      }
    }
  }
}
</script>
